.custom-list {
  list-style-type: none !important
}

.dark {
  background-color: #18191a !important;
  color: #ffffff !important;
}

.light {
  background-color: #f7f7f7 !important;
  color: #333333 !important;
}

@media screen and (max-width: 1199px) {
  .header--main .dark-nav-btn {
    display: block;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1270px;
  }
}

.website-name {
  color: #FFA227;
  font-size: 18px;
  font-weight: bolder;
}

.nav-dark {
  color: #f9f9f9 !important;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.main-nav {
  margin-left: 35px;
}

.checkbox-label {
  background-color: #1e1c1c;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.css-1wsz2x4 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  color: #EAECEF;
}

.fa-moon {
  color: #f1c40f;
}

.fa-sun {
  color: #f39c12;
}

.app-connect {
  gap: 48px;
  color: #fff;
  margin-top: 85px;
}

.home-section-main {
  padding: 70px 0 59px;
  background-size: cover;
  background-color: #18191a;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked+.checkbox-label .ball {
  transform: translateX(24px);
}

.dark-light-toggle {
  padding: 9px;
}

.listing-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
}

.listing-contact {
  background-color: transparent !important;
  font-size: 14px;
}

.listing-checkbox {
  height: 19px;
  width: 19px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.checkbox-wrapper input {
  margin-right: 8px;
}

.f-social {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.header {
  max-width: 100vw;
  height: 61px;
  position: relative;
  /* display: none; */
  padding: 0 24px;
  justify-content: space-between;
}
.header-hidden {
  display: none !important;
}
.footer-hidden {
  display: none !important;
}
.page-logo {
  width: 227px;
}

.language {
  width: 24px;
  height: 24px;
  font-size: 24px;
  cursor: pointer;
}

.change {
  border: none !important;
  width: 30px !important;
  height: 60px !important;
}

.lang-list {
  display: none;
  position: absolute;
  top: 61px;
  width: 140px;
  left: -118px;
  border-radius: 10px !important;
  z-index: 999999;
  border-top: 1px solid #cfd0d2 !important;
}

.lang-btn-dark {
  background: rgb(30, 35, 41) !important;
  color: white;
  display: block;
  width: 100%;
  border-bottom: none;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  position: relative;
  left: 0;
  padding-left: 33px;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.4;
}

.lang-list__item .lang-btn-dark:hover {
  background: #FFA227 !important;
  color: #fff;
  transition: all .15s ease-in;
}

.dark .lang-list .lang-list__item {
  background: rgb(30, 35, 41);
  border-radius: 10px;
  padding: 5px;
}

.lang-list .lang-list__item {
  background: white;
  border-radius: 10px;
  padding: 5px;
}

.header-btn {
  min-width: 70px !important;
  border-radius: 10px;
}

.home__title_new {
  font-size: 60px;
  line-height: 80px;
  color: #FFA227;
  font-weight: bold;
}

.form-item-home {
  border: 1px solid #A2A2A2;
  height: 55px;
  border-radius: 10px;
}

.home-signup {
  align-items: baseline;
}

.sign-btn {
  margin-left: 6px
}

.home-address {
  width: 56%;
}

.home-sign-btn {
  display: block;
  margin-top: 15px;
  background: #FFA227;
  min-width: 139px;
  height: 55px;
  line-height: 50px;
  border-radius: 10px;
  color: #5F3600;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
}

.subtitle-color {
  color: #a59d9d;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}

.google-img {
  border-radius: 10px;
  border: solid 1px #a1a6ad;
  border-color: #2B3139;
  padding: 11px 11px;
  line-height: 1;
  cursor: pointer;
  display: -webkit-flex;
  width: -webkit-fit-content;
}

.app-qr__item {
  background: rgb(30 35 41);
}

.app-qr__item_light {
  border: 0.8px solid rgb(248 251 255);
  border-radius: 0px 0px 8px 8px;
  background: #f1f0f0;
  box-shadow: 0px 8px 8px 0px rgba(24, 26, 32, .08);
  margin-top: 0px !important;
  padding: 12px;
  border-radius: 10px;
}

.home-connect {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  gap: 16px;
}

.scan-color {
  color: rgb(240, 185, 11);
}

.second-part {
  display: flex;
}

.css-1045iph {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #1E2329;
  padding: 9px;
}

.crypto-row {
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
}

.cred-form-listing {
  padding: 20px;
  background: #1c1d1e;
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, .1);
  border-radius: 10px;
}
.login-dark{
  box-shadow: 0 4px 15px rgb(185 178 178 / 10%) !important;
}
.field-label--type2 {
  padding: 10px 0;
  background-color: transparent !important;
  text-transform: none;
  font-size: 14px;
}

.radio+label {
  position: relative;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  background-color: transparent !important;
}

.form-item--type3 {
  /* background-color: transparent !important; */
  padding: 0 15px;
  border: 1px solid #A2A2A2;
}

.crypto-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.crypto-price,
.crypto-percentage {
  margin-right: 10px;
}

.crypto-price {
  font-weight: bold;
  color: #EAECEF;
}

.crypto-percentage {
  font-weight: bold;
  color: rgb(3, 166, 109);
  margin-right: 10px;
}

.home-third {
  max-width: 450px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #1E2329;
  padding: 13px;
  margin-top: 14px;
}

.home-img {
  margin-top: 10px;
}

.coinRow-coinCode {
  box-sizing: border-box;
  margin: 0px 4px 0px 8px;
  min-width: 0px;
  color: #EAECEF;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
}

.css-quuoyd {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  color: rgb(112, 122, 138) !important;
  white-space: nowrap;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
}

.css-it66m8 {
  width: auto;
  margin-right: -6px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: #1E2329;
  padding: 17px;
  margin-top: 20px;
}

.css-17kwjv7 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  gap: 16px;
  width: 100%;
  color: #EAECEF;
  flex-wrap: wrap;
  margin-top: 20px;
}

.css-4m3p11 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #EAECEF;
}

.css-1bvtraz {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  display: flex;
  height: 32px;
  gap: 16px;
}

.css-i8d16c {
  box-sizing: border-box;
  margin: 0px;
  display: flex;
  align-items: baseline;
  font-weight: 500;
  font-size: 20px;
  color: #EAECEF;
  gap: 3px;
}

.date {
  box-sizing: border-box;
  margin: 0px;
  min-width: 0px;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #EAECEF;
}

.coin-section {
  width: 100%;
  padding: 75px 0;
  background-color: #18191a;
}

.cointainer_coin {
  width: 100%;
  margin: auto;
  display: flex;
  gap: 50px;
}

.coinsection_left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.coin_img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.coin_img img {
  max-height: 500px;
}

.coin_text {
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: #EAECEF;
}

.links {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 10px;
  border-bottom: 2px solid;
  border-color: #EAECEF;
}

.links-border {
  border-color: rgb(43, 49, 57);

}

.links li {
  padding-bottom: 10px;
}

.links button {
  color: white;
}

.links_style {
  padding: 0 40px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.coinsection_right {
  display: flex;
  flex-direction: column;
  gap: 50px;
  flex: 1;
  justify-content: end;
}

.qr_section {
  display: flex;
  gap: 20px;
}

.qr_img img {
  border: 1px solid #848E9C;
  border-radius: 10px;
  padding: 10px;
}

.qr_text h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #848E9C;
  margin-bottom: 10px;
}

.qr_text h1 {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: white;
}

.qr_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icons {
  display: flex;
  gap: 50px;
  width: 100%;
}

.coin_icon_size {
  width: 40px;
  height: 40px;
  color: white;
}

.icon_mac {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_mac a {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  padding: 5px;
}
.footer-logo{
  width: 122px;
}
.icon_mac a:hover {
  background-color: #252A32;
  border-radius: 10px;
}
.card-align {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.css-1c0zcq0 {
  width: 20px;
  height: 20px;
}
.market-text {
  text-align: justify;
}
.more_option a {
  display: flex;
  align-items: center;
  color: white;
}

.link-active .active {
  border-bottom: 2px solid #F0B90B;
}

@media only screen and (max-width: 1024px) {
  .cointainer_coin {
    max-width: 80%;
    display: flex;
    flex-direction: column;
  }

  .links {
    margin-top: 30px;
  }

  .coinsection_right {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icons {
    justify-content: center;
    gap: 0px;
  }
}

.page-section {
  width: 100%;
}

.justify-space-between {
  justify-content: space-between;
}

.question {
  width: 100%;
  margin: auto;
}

.question_heading {
  text-align: center;
  margin-bottom: 64px;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
}

.question_list {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.question_list button {
  color: white;
}

.question_flex {
  display: flex;
  gap: 20px;
}

.question_text {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.question_number {
  border: 1px solid #e6e9ed;
  padding: 5px 15px;
  border-radius: 10px;
  height: max-content;
}

.content-shown .question_button_button {
  background-color: #FCD535;
}

.content-shown .question_button_button svg path {
  fill: black !important;
}

.question_list:hover,
.content-shown {
  background-color: #1E2329;
  border-radius: 10px;
}

.content-shown-light .content-shown {
  background-color: #fff;
  border-radius: 10px;
}

.question_list:hover .question_button_button {
  background-color: #FCD535;
}

.question_list:hover .question_button_button svg path {
  fill: black !important;
}

.question_list_dark {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.question_list_dark button {
  color: black;
}

.auth {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .auth {
    display: block;
  }
}

.question_list_dark:hover .question_button_button {
  background-color: #FCD535;
}

.question_button_button {
  padding: 7px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.lang-list__item .lang-btn {
  border-top: none;
}

.question_button button svg path {
  fill: white;
}

.question_list_dark .question_button button svg path {
  fill: black;
}

.content_question {
  margin-top: 10px;
}

.additional_content {
  font-size: 16px;
}

.additional_content p {
  margin-top: 10px;
}

.highlight_faq {
  color: #FCD535;
}

.content-shown .question_button_button {
  background-color: #FCD535;
}

.content-shown .question_button_button svg path {
  fill: black !important;
}

.view_more {
  text-align: center;
  margin: 30px 0;
}

.view_more a {
  color: #FCD535;
}

.page-logo-footer {
  width: 100%;
  height: 100%;
  text-align: center;

}
.bg-new-img{
  object-fit: none !important;
}
.vote-image{
  padding: 30px 0;
}

.font-13 {
  font-size: 13px;
  margin-top: 25px;
}

.home__subtitle {
  margin-top: 62px !important;
}

.content-shown {
  color: white;
  border-radius: 10px;
}

.content-shown-light {
  color: black;
  border-radius: 10px;
  background-color: white;
}

.lang-drop.active {
  border-bottom: none;
}

.qr-list {
  display: none;
  position: absolute;
  top: 58px;
  left: -125px;
  border-radius: 10px;
  z-index: 2;
}

.qr-list .lang-list__item {
  background: none;
}

.qr-list .app-qr__item {
  padding: 12px;
  border-radius: 10px;
}

.qr-list.hidden {
  display: none;
}

.qr-list.show {
  display: block;
  z-index: 9;
}

.qr-list .ieo-card__text {
  margin-top: 0px;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: #fff;
}

.light-ieo-card__text {
  margin-top: 0px;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  color: black;
}

.header-btn {
  min-width: 70px !important;
  border-radius: 10px;
}

.b_more_download {
  display: block;
  margin-top: 15px;
  background: #FFA227;
  width: 130px;
  line-height: 40px;
  border-radius: 3px;
  color: #5F3600;
  font-size: 10px;
  text-align: center;
  font-weight: 700;
}

.content_wrapper {
  width: 100%;
  max-width: 1320px;
  margin: auto;
  padding: 0 20px;
}

.wrapper_home {
  padding: 0px;
  margin: 0px;
  max-width: 1200px;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
}

.footer-column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: auto;
}

.font-13 {
  margin-top: 0px;
  text-align: center;
}

.cred {
  margin: 0px;
}

.common {
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.auth {
  display: none;
}

@media only screen and (max-width: 1199px) {
  .auth {
    display: block;
  }
}

.lang-drop>li {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #CFD0D2;
}

.lang-drop {
  margin: 0;
}

.header {
  margin-top: 10px;
}

@media screen and (max-width: 1024px) {
  .footer-content {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    height: max-content;
  }

  .font-13 {
    text-align: left;
  }

  .header_sign_btn_group {
    display: none;
  }

  .icon_wrap {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
  }

  .question_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .footer-content {
    grid-template-columns: 1fr;
    padding: 30px;
  }

  .font-13 {
    text-align: left;
  }

  .f-nav-block {
    text-align: left !important;
  }

  .page-logo-footer {
    max-width: 200px;
    text-align: left;
  }

  .f-nav {
    text-align: left !important;
  }

  .icon_wrap {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .question_text {
    font-size: 12px;
  }

  .f-nav-block {
    text-align: left;
  }

  .f-nav {
    text-align: left;
  }
}


.css-1wsz2x4 .vip-ins {
  background-color: #2B3139;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 60px 0;
}
@media screen and (min-width: 767px) {
  .css-1wsz2x4 .api {
    background-image: url(https://bin.bnbstatic.com/static/images/landing-page/api/dark-theme/header-bg-pc-tinify.png);
  }
}

.css-1wsz2x4 .api {
  background-color: #181A20;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(../img/header-bg-pc-tinify.png);

  /* background-image: url(https://bin.bnbstatic.com/static/images/landing-page/api/dark-theme/header-bg-mobile-tinify.png); */
}

.upload-docs {
  display: flex !important;
}
.uploaded-image {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.uploaded-image img {
  height: 125px !important;
  object-fit: contain;
}
.document-content {
  font-size: small;
  margin-bottom: 6px
}
.w-100 {
  width: 100%;
}
.w-62 {
  width: 62%;
}
@media only screen and (max-width: 1235px) {
  .w-62 {
    width: 50% !important;
  }
}
@media only screen and (max-width: 1024px) {
  .w-62 {
    width: 100% !important;
  }
}
.tradingview-widget-container__widget div {
  width: auto !important;
}
.dark-color{
  color: #ffffff !important;
}
.td-background{
  background: rgb(80 77 77 / 50%);
}
.dark-page-tab__item:hover{
  color: #a95555 !important;
}
.dark-td{
  border-bottom: 1px solid #3a3e42 !important;
}
.exchange-background{
  background: rgb(80 77 77 / 50%);
}
.cashback-dark{
  color: #18191a;
}
.form-filed-background {
  background-color: transparent !important;
}
.exchange-drop{
  background-color: black !important;
}
.drop-list__item .drop-btn .drop-icon.dark-icon-dd svg path {
  stroke: white !important;
  fill: white !important;
}
.footer-paiblock{
  text-align: center;
  margin-bottom: 10px;
}